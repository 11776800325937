import React, { useEffect, useState } from "react";
import Header from "../components/header";
import Footer from "../components/Footer";
import Loader from "../components/Loader";
import green from "../Assets/Images/green.png";
import { Modal } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { BASE_URL, TOURNAMENT_DETAILS, PLAYER_LIST, LEAVE_TOURNAMENT } from "../API";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import axios from "axios";
import game1 from "../Assets/Images/game1.png";
import ludo from "../Assets/Images/ludo.png";
import pubg from "../Assets/Images/pubg.png";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import speed from "../Assets/Images/speed.png";
import gorup2 from "../Assets/Images/Group (2).png";
import hexa from "../Assets/Images/hexa.png";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import EnrollNowModal from "../components/EnrollNowModal";
import moment from "moment";
function ViewTournament() {
  const location = useLocation();
  const [tournamentDetails, setTournamentDetails] = useState('');
  // const [totalParticipants, setTotalParticipants] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [isPlayerModal, setIsPlayerModal] = useState(false);
  const [bracketImage, setBracketImage] = useState("");
  const [isLogin, setIsLogin] = useState(localStorage.getItem('user'))
  const [tournamentParticipants, setTournamentParticipants] = useState([])
  const [show, setShow] = useState(false);
  const [teamPlayers, setTeamPlayers] = useState([])
  const [selectedTeam, setSelectedTeam] = useState()
  const [showLeave, setShowLeave] = useState(false)
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));

  const navigate = useNavigate()
  useEffect(() => {
    window.scrollTo(0, 0);
    if (!location.state) {
      navigate("/");
    }
    console.log("details")
    getTournamentDetails();
  }, []);

  const getTournamentDetails = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");

      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      console.log("game")
      const params = {
        id: location.state.element.id,
        user_id: user?.id
      };

      const response = await axios.get(BASE_URL + TOURNAMENT_DETAILS, {
        headers, params,
      });

      if (response.data.status_code === 200 && response.data.status === true) {
        setIsLoading(false);
        console.log(response.data)
        setTournamentParticipants(response.data.tournamentParticipants)
        setTournamentDetails(response.data.tournament)
        setBracketImage(response.data.tournament.bracket_image_url)
      } else if (
        response.data.status_code === 400 &&
        response.data.status === false
      ) {
        toast.error(response?.data?.error[0]);
        setIsLoading(false);
      } else if (
        response.data.status_code === 403 &&
        response.data.status === false
      ) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        // console.log('response.data::',response)
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message || "An error occurred";
        setIsLoading(false);
        toast.error(errorMessage);
      } else {
        // toast.error("An error occurred");
        setIsLoading(false);
      }
    }
  };

  const closeModel = () => { setIsShowModal(false) }
  const openModel = () => { setIsShowModal(true) }

  const handleEnroll = () => {
    if (!isLogin) { toast.success("Please Login First") }
    else { openModel(); }
  }

  const handleViewTeam = (team) => {
    setShow(true);
    setSelectedTeam(team);
    getPlayerList(team);
  };


  const getPlayerList = async (team) => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const response = await axios.get(BASE_URL + PLAYER_LIST + '?team_id=' + team.team_id, {
        headers: headers,
      });

      if (response.data.status_code === 200 && response.data.status === true) {
        console.log(response.data);
        setTeamPlayers(response.data.players);
        setIsLoading(false);
      } else if (
        response.data.status_code === 400 &&
        response.data.status === false
      ) {
        toast.error(response?.data?.error[0]);
        setIsLoading(false);
      } else if (
        response.data.status_code === 403 &&
        response.data.status === false
      ) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        // console.log('response.data::',response)
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message || "An error occurred";
        setIsLoading(false);
        toast.error(errorMessage);
      } else {
        // toast.error("An error occurred");
        setIsLoading(false);
      }
    }
  };

  const handleLeaveTournament = (team) => {
    setShowLeave(true);
    setSelectedTeam(team);
    getPlayerList(team);
  }
  const leaveTournament = async () => {
    setIsLoading(true);
    console.log(' ====> ', user.id)
    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const response = await axios.delete(BASE_URL + LEAVE_TOURNAMENT,
        {
          headers,
          data: {
            tournament_id: tournamentDetails.id,
            captain_id: user.id
          }
        });

      if (response.data.status_code === 200 && response.data.status === true) {
        toast.success(response.data.message);
        navigate("/tournament");
      } else if (
        response.data.status_code === 400 &&
        response.data.status === false
      ) {
        toast.error(response?.data?.error[0]);
        setIsLoading(false);
      } else if (
        response.data.status_code === 403 &&
        response.data.status === false
      ) {
        setIsLoading(false);
      } else {
        toast.error(response.data.message);
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message || "An error occurred";
        toast.error(errorMessage);
        setIsLoading(false);
      } else {
        toast.error("An error occurred");
        setIsLoading(false);
      }
    }
  }

  return (
    <>
      <Loader isLoading={isLoading} />
      {isShowModal && <EnrollNowModal isShowModal={isShowModal} closeModel={closeModel} tournament_id={tournamentDetails.id} />}
      <Header />
      <section className="back-profile">
        <Container>
          <div className="inner-banner-prof">
            <img src={green} alt="" />
            <p className="subtitle-hero"># World Class ESports & Gaming Site</p>
            <h1>
              <span className="span-heromain">
                {tournamentDetails.tournament_name ? tournamentDetails.tournament_name : 'N/A'}
              </span>
            </h1>
          </div>
        </Container>
      </section>

      <section className="comn-padd pb-5">
        <Container>
          <div className="barmunda-traning">
            <div className="inner-brmunda d-flex">
              <div className="one-sec-barmun">
                <h3>{tournamentDetails.tournament_name}</h3>
                <p>{tournamentDetails.description}</p>
              </div>
              <div className="sec-section-barmund border-main">
                <p>Tournament Prize Pool</p>
                <h6>£ {tournamentDetails.winning_prize ? tournamentDetails.winning_prize : 'N/A'}</h6>
              </div>
              <div className="sec-section-barmund border-main">
                <p>Total Teams</p>
                <h6>{tournamentDetails.total_teams ? tournamentDetails.total_teams : 'N/A'}</h6>
              </div>
              <div className="sec-section-barmund border-main">
                <p>Team size</p>
                <h6>{tournamentDetails.team_size ? tournamentDetails.team_size : 'N/A'}</h6>
              </div>
              <div className="enrll-now-btn">
                <div class="d-xxl-block mb-3 mt-0">
                  {tournamentDetails && tournamentDetails.tournament_state === "pending" ? (
                    tournamentDetails.participate ? (
                      <button className="th-btn th_btn" onClick={() => handleLeaveTournament()}>
                        Leave Tournament
                      </button>
                    ) : (
                      <button className="th-btn th_btn" onClick={handleEnroll}>
                        Enroll now
                      </button>
                    )
                  ) : (
                    <button className="th-btn th_btn" onClick={handleEnroll} style={{ pointerEvents: 'none' }}>
                      {tournamentDetails && tournamentDetails.tournament_state === 'underway' ?
                        'Tournament Started' : isLogin ? 'Tournament Ended' : 'N/A'}
                    </button>
                  )}
                </div>
                <p>
                  Registration ends on{" "}
                  {moment(tournamentDetails.registration_deadline_date).format('DD-MM-YYYY HH:mm')}
                </p>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <section className="tabs-sec">
        <Tabs
          defaultActiveKey="Overview"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="Overview" title="Overview">
            <Container>
              <div className="inner-main-content-view">
                <h3 className="amin-head-comn">
                  {tournamentDetails.tournament_name}
                </h3>
                <Row>
                  <Col lg={12}>
                    <div className="inner-para-main">
                      <p className="text-main-all">
                        {tournamentDetails.description}
                      </p>
                      <div className="start-date-end-all d-flex">
                        <div className="first-sec-date">
                          <h6>start date</h6>
                          <p> {moment(tournamentDetails.tournament_start_date).format('DD-MM-YYYY HH:mm')}</p>
                        </div>
                        <div className="first-sec-date">
                          <h6>End date</h6>
                          <p>  {moment(tournamentDetails.tournament_end_date).format('DD-MM-YYYY HH:mm')}</p>
                        </div>
                        <div className="first-sec-date">
                          <h6>region</h6>
                          <p> {tournamentDetails.region}</p>
                        </div>
                        <div className="first-sec-date">
                          <h6>Credit Per Team</h6>
                          <p> {tournamentDetails.entry_fee}</p>
                        </div>
                      </div>
                    </div>
                  </Col>
                  {/* <Col lg={3}>
                    <div className="invitation-link">
                      <h5>Invite Players</h5>
                      <Form.Group md="4">
                        <Form.Label>Invitation Link</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Enter Here"
                          defaultValue="Otto"
                        />
                      </Form.Group>
                      <div class="d-xxl-block mb-0 mt-3">
                        <a class="th-btn th_btn" href="/signup">
                          COPY LINK <i class="fa-solid fa-arrow-right ms-2"></i>
                        </a>
                      </div>
                      <div className="or">
                        <p>Or</p>
                      </div>
                      <div className="sharedlinks">
                        <h5>Share Invitation</h5>
                        <div className="links-insta">
                          <div className="media-icons d-flex">
                            <Link to="" className="link-icons">
                              <i class="fa-brands fa-instagram"></i>
                            </Link>
                            <Link to="" className="link-icons">
                              <i class="fa-brands fa-facebook-f"></i>
                            </Link>
                            <Link to="" className="link-icons">
                              <i class="fa-brands fa-youtube"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col> */}
                </Row>
                <div>
                  <div className="editor-html"
                    dangerouslySetInnerHTML={{
                      __html: tournamentDetails.overview_description,
                    }}
                  ></div>
                </div>
                {/* <h3 className='amin-head-comn'>TOURNAMENT RULES</h3>
                        <p className='text-main-all'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas nisi orci, pharetra vulputate ipsum sed, egestas suscipit diam. Nunc nec tincidunt ipsum, at finibus ante. Vivamus porta risus at molestie accumsan. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas nisi orci, pharetra vulputate ipsum sed, egestas suscipit diam. Nunc nec tincidunt ipsum, at finibus ante. Vivamus porta risus at molestie accumsan. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas nisi orci, pharetra vulputate ipsum sed, egestas suscipit diam. Nunc nec tincidunt ipsum, at finibus ante. Vivamus porta risus at molestie accumsan. </p>
                        <ul>
                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusdunt ut labore et dolore magna aliqua.</li>
                        </ul> */}
              </div>
            </Container>
          </Tab>
          <Tab eventKey="Prizes" title="Prizes">
            <Container>
              <div className="inner-main-content-view">
                <h3 className="amin-head-comn">PRIZES</h3>
                <Row>
                  <Col lg={9}>
                    <div className="prizes-main d-flex gap-4">
                      <div className="first-prize-main d-flex">
                        <div className="upper-wrap-prize d-flex">
                          <div className="upper-hexa-img">
                            <img src={hexa} alt="" />
                            <div className="inner-list-img">
                              <img src={gorup2} alt="" />
                            </div>
                          </div>
                          <p>Total Prize pool</p>
                        </div>
                        <p className="dolar-main">£{tournamentDetails.winning_prize}</p>
                      </div>
                      {/* <div className="first-prize-main d-flex">
                                        <div className="upper-wrap-prize d-flex">
                                            <div className="upper-hexa-img">
                                                <img src={hexa} alt="" />
                                                <div className="inner-list-img">
                                                    <img src={gorup2} alt="" />
                                                </div>
                                            </div>
                                            <p>1st Prize</p>
                                        </div>
                                        <p className='dolar-main'>$120</p>
                                    </div> */}
                    </div>
                    {/* <div className="inner-para-main">
                                    <p className='text-main-all'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas nisi orci, pharetra vulputate ipsum sed, egestas suscipit diam. Nunc nec tincidunt ipsum, at finibus ante. Vivamus porta risus at molestie accumsan. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas nisi orci, pharetra vulputate ipsum sed, egestas suscipit diam. Nunc nec tincidunt ipsum, at finibus ante. Vivamus porta risus at molestie accumsan. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas nisi orci, pharetra vulputate ipsum sed, egestas suscipit diam. Nunc nec tincidunt ipsum, at finibus ante. Vivamus porta risus at molestie accumsan. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas nisi orci, pharetra vulputate ipsum sed, egestas suscipit diam. Nunc nec tincidunt ipsum. Maecenas nisi orci, pharetra vulputate ipsum sed, egestas suscipit diam. Nunc nec tincidunt ipsum, at finibus ante. Vivamus porta risus at molestie accumsan. </p>
                                    <p className='text-main-all'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas nisi orci, pharetra vulputate ipsum sed, egestas suscipit diam. Nunc nec tincidunt ipsum, at finibus ante. Vivamus porta risus at molestie accumsan. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas nisi orci, pharetra vulputate ipsum sed, egestas suscipit diam. Nunc nec tincidunt ipsum, at finibus ante. Vivamus porta risus at molestie accumsan. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>

                                </div> */}
                    <div>
                      <div className="editor-html"
                        dangerouslySetInnerHTML={{
                          __html: tournamentDetails.prize_description,
                        }}
                      ></div>
                    </div>
                  </Col>
                  {/* <Col lg={3}>
                    <div className="invitation-link">
                      <h5>Invite Players</h5>
                      <Form.Group md="4">
                        <Form.Label>Invitation Link</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Enter Here"
                          defaultValue="Otto"
                        />
                      </Form.Group>
                      <div class="d-xxl-block mb-0 mt-3">
                        <a class="th-btn th_btn" href="/signup">
                          COPY LINK <i class="fa-solid fa-arrow-right ms-2"></i>
                        </a>
                      </div>
                      <div className="or">
                        <p>Or</p>
                      </div>
                      <div className="sharedlinks">
                        <h5>Share Invitation</h5>
                        <div className="links-insta">
                          <div className="media-icons d-flex">
                            <Link to="" className="link-icons">
                              <i class="fa-brands fa-instagram"></i>
                            </Link>
                            <Link to="" className="link-icons">
                              <i class="fa-brands fa-facebook-f"></i>
                            </Link>
                            <Link to="" className="link-icons">
                              <i class="fa-brands fa-youtube"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col> */}
                </Row>

                {/* <ul>
                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusdunt ut labore et dolore magna aliqua.</li>
                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusdunt ut labore et dolore magna aliqua.</li>
                        </ul> */}
              </div>
            </Container>
          </Tab>
          <Tab eventKey="Teams" title="Teams">
            <Container>
              <div className="inner-main-content-view">
                <h3 className="amin-head-comn">Participants</h3>
                <Row>
                  <Col lg={9}>
                    <div className="table-maintourna">
                      <table className="w-100">
                        <tr>
                          <th>Team Name</th>
                          <th>Captain</th>
                          <th>Action</th>
                          {/* <th>Team Size</th> */}
                        </tr>
                        {tournamentParticipants.length > 0 ? tournamentParticipants.map((team, key) => {
                          return (
                            <tr key={key}>
                              <td>{team.team_name}</td>
                              <td>{team.captain_name}</td>
                              <td>
                                <button className="edit-butt" onClick={() => handleViewTeam(team)}>
                                  View
                                </button>
                              </td>
                              {/* <td>{team.total_players}</td> */}
                            </tr>
                          )
                        }) : <tr>
                          <td className="text-center" colSpan={4}>No Participants yet</td>
                        </tr>}


                        {/* <tr>
                          <td>Kadin Schleifer</td>
                          <td>Lorem Ipsum</td>
                          <td>
                            <button className="accept-btn">Accepted</button>
                          </td>
                          <td>6546515416541515</td>
                        </tr> */}
                        {/* <tr>
                          <td className="text-center" colSpan={4}>No Participants yet</td>
                        </tr> */}
                        {/* <tr>
                          <td>Kadin Schleifer</td>
                          <td>Lorem Ipsum</td>
                          <td>
                            <button className="accept-btn">Accepted</button>
                          </td>
                          <td>6546515416541515</td>
                        </tr>
                        <tr>
                          <td>Kadin Schleifer</td>
                          <td>Lorem Ipsum</td>
                          <td>
                            <button className="accept-btn">Accepted</button>
                          </td>
                          <td>6546515416541515</td>
                        </tr>
                        <tr>
                          <td>Kadin Schleifer</td>
                          <td>Lorem Ipsum</td>
                          <td>
                            <button className="accept-btn">Accepted</button>
                          </td>
                          <td>6546515416541515</td>
                        </tr> */}
                      </table>
                    </div>
                  </Col>
                  {/* <Col lg={3}>
                    <div className="invitation-link">
                      <h5>Invite Players</h5>
                      <Form.Group md="4">
                        <Form.Label>Invitation Link</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Enter Here"
                          defaultValue="Otto"
                        />
                      </Form.Group>
                      <div class="d-xxl-block mb-0 mt-3">
                        <a class="th-btn th_btn" href="/signup">
                          COPY LINK <i class="fa-solid fa-arrow-right ms-2"></i>
                        </a>
                      </div>
                      <div className="or">
                        <p>Or</p>
                      </div>
                      <div className="sharedlinks">
                        <h5>Share Invitation</h5>
                        <div className="links-insta">
                          <div className="media-icons d-flex">
                            <Link to="" className="link-icons">
                              <i class="fa-brands fa-instagram"></i>
                            </Link>
                            <Link to="" className="link-icons">
                              <i class="fa-brands fa-facebook-f"></i>
                            </Link>
                            <Link to="" className="link-icons">
                              <i class="fa-brands fa-youtube"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col> */}
                </Row>
              </div>
            </Container>
          </Tab>
          <Tab eventKey="Brackets" title="Brackets">
            <Container>
              <div className="inner-main-content-view">
                <h3 className="amin-head-comn">Brackets</h3>
                {tournamentDetails.tournament_state != "pending" ? (<img src={bracketImage} />)
                  : (
                    <p className="text-main-all">Brackets will be shown once tournament started</p>
                  )}
              </div>
            </Container>
          </Tab>
        </Tabs>
      </section>

      <section className="profesniol comn-padd how-it-work">
        <Container>
          <div className="inner-rpofesional">
            <h1 class="span-heromain">
              <span >Eu Pro Scene</span> <br />
              {/* <span>Professional EuEsport Gaming</span> <br />{" "}
              <span className="tournament-main">TOURNAMENT</span> */}
            </h1>

            {!isLogin &&
              <div class="header-button d-flex">
                <a class="login-head" onClick={() => navigate('/login')}>
                  Login
                </a>
                <div class="d-xxl-block">
                  <a class="th-btn th_btn" onClick={() => navigate('/signup')}>
                    REGISTER NOW
                  </a>
                </div>
              </div>
            }
          </div>
        </Container>
      </section>

      <Footer />
      <Modal className="modal-main-width" show={show} onHide={() => setShow(false)}>
        <Modal.Body className="modal-padd">
          <div>
            <div className="inner-ogout-btnn">

              <h2>{selectedTeam?.team_name}</h2>
            </div>
            <hr />
            <div className="inner-ogout-btnn justify-content-start gap-4 inner-team-disc">
              <h6>Team Captain : </h6>
              <p>{selectedTeam?.captain_name}</p>
            </div>

            <div className="table-maintourna pop-tournamnt">
              <table className="w-100">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Name</th>
                    <th>PSN ID</th>
                    <th>Activision ID</th>
                    <th>Xbox GamerTag</th>
                    <th>EA ID</th>
                    <th>Ubisoft ID</th>
                  </tr>
                </thead>
                <tbody>
                  {teamPlayers && teamPlayers.length > 0 ? (
                    teamPlayers.map((player, index) => (
                      player.is_accepted == 1 || player.is_accepted == 2 ? (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{player.name}</td>
                          <td>{player.user_details.psn ? player.user_details.psn : '-'}</td>
                          <td>{player.user_details.activision ? player.user_details.activision : '-'}</td>
                          <td>{player.user_details.xbox ? player.user_details.xbox : '-'}</td>
                          <td>{player.user_details.ea ? player.user_details.ea : '-'}</td>
                          <td>{player.user_details.ubisoft ? player.user_details.ubisoft : '-'}</td>
                        </tr>
                      ) : null
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3">No Players found in the team</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <div className="upper-btn-llogout-main d-flex mt-4">
            <button onClick={() => { setShow(false) }} className="login-head">
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal className="modal-logout" show={showLeave} onHide={() => setShowLeave(false)}>
        <Modal.Body className="modal-padd">
          <div className="inner-ogout-btn">
            <h2>Are You sure you want to leave the Tournament?</h2>
            <p className="text-damble-text">
              The action you are going to perform is irreversible please
              Confirm! Are you sure that you want to proceed?
            </p>
          </div>
          <div className="upper-btn-llogout-main d-flex">
            <Link onClick={() => setShowLeave(false)} className="login-head">
              Close
            </Link>
            <a className="edit-butt" onClick={() => { leaveTournament(); setShowLeave(false) }}>
              Exit
            </a>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ViewTournament;
