import React from 'react'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BASE_URL, GET_SUBSCRIPTION_PLANS, SAVE_SUBSCRIPTION_PLAN, GET_SUBSCRIPTION_DETAILS } from "../API";
import { Modal, Button } from "react-bootstrap";
import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import axios from 'axios';
import { toast } from "react-toastify";
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import Loader from "./Loader";
import redlogo from '../Assets/Images/redogo.png'
import greentick from '../Assets/Images/greentick.png'
import tick from '../Assets/Images/tic.png'
function Subscription({ isLoading, setIsLoading, getUserDetails }) {
    const [show, setShow] = useState(false);
    const [subscriptionPlans, setSubscriptionPlans] = useState([])
    const [selectedPlan, setSelectedPlan] = useState();
    const [userSubscription, setUserSubscription] = useState([]);
    const [error, setError] = useState(null);
    const navigate = useNavigate()

    const PayPalConfig = {
        "client-id": "AXbyx1ruYO3_TurVWai9ZzoGHzaCd1fxC58a7j3WM8g1r2athPgIacXexjsRMkdqqowMsyeNMLPxRRlY",
        "currency": "GBP"
    };

    useEffect(() => {
        setIsLoading(true)
        const fetchData = async () => {
            await userSubscriptionPlans();
            await getSubscriptionDetails();
        }
        fetchData();
    }, [])


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handlePlanSelect = (data) => {
        setSelectedPlan(data);
    };

    const createOrder = (data, actions) => {
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        value: selectedPlan.price,
                        currency_code: "GBP",
                        breakdown: {
                            item_total: {
                                currency_code: "GBP",
                                value: selectedPlan.price,
                            },
                        },
                    },

                    items: [
                        {
                            unit_amount: {
                                currency_code: "GBP",
                                value: selectedPlan.price,
                            },
                            quantity: "1",
                            name:
                                "GBP " +
                                selectedPlan.price,
                        },
                    ],
                },
            ],
        });
    };

    const onApprove = (data, actions) => {
        return actions.order
            .capture()
            .then(async function (details) {
                if (details && details.status === "COMPLETED") {
                    const formData = {
                        transaction_id: details.id,
                        transaction_status: details.status,
                        transaction_amount: details.purchase_units[0].amount.value,
                        plan_duration: selectedPlan.duration,
                        plan_name: selectedPlan.name,
                        plan_status: 'active',
                    };
                    setIsLoading(true);
                    try {
                        const token = localStorage.getItem("token");
                        const headers = {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                        };

                        const response = await axios.post(BASE_URL + SAVE_SUBSCRIPTION_PLAN, JSON.stringify(formData), { headers: headers });

                        if (response.data.status_code === 200 && response.data.status === true) {
                            setIsLoading(false);
                            toast.success(response.data.message);
                            setShow(false);
                            userSubscriptionPlans();
                            getUserDetails();
                        } else if (
                            response.data.status_code === 400 &&
                            response.data.status === false
                        ) {
                            toast.error(response?.data?.error[0]);
                            setIsLoading(false);
                        } else if (
                            response.data.status_code === 403 &&
                            response.data.status === false
                        ) {
                            localStorage.removeItem("user");
                            localStorage.removeItem("token");
                            navigate("/login");
                        } else {
                            // console.log('response.data::',response)
                            toast.error(response.data.message);
                        }
                    } catch (error) {
                        if (error.response && error.response.data) {
                            const errorMessage =
                                error.response.data.message || "An error occurred";
                            setIsLoading(false);
                            toast.error(errorMessage);
                        } else {
                            toast.error("An error occurred");
                            setIsLoading(false);
                        }
                    }
                } else {
                }
            })
            .catch((error) => {
                alert('ddd')
                console.error("Error occurred during payment approval:", error);
            });
    };

    const getSubscriptionDetails = async () => {
        try {
            const token = localStorage.getItem("token");
            const headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            };

            const response = await axios.get(
                BASE_URL + GET_SUBSCRIPTION_PLANS,
                {
                    headers: headers,
                }
            );

            if (response.data.status_code === 200 && response.data.status === true) {

                console.log(response.data.subscription)
                setSubscriptionPlans(response.data.subscription);
                setIsLoading(false);
            } else if (
                response.data.status_code === 400 &&
                response.data.status === false
            ) {
                toast.error(response?.data?.error[0]);
                setIsLoading(false);
            } else if (
                response.data.status_code === 403 &&
                response.data.status === false
            ) {
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                navigate("/login");
                setIsLoading(false);

            } else {
                // console.log('response.data::',response)
                toast.error(response.data.message);
            }
        } catch (error) {
            if (error.response && error.response.data) {
                const errorMessage = error.response.data.message || "An error occurred";
                setIsLoading(false);
                toast.error(errorMessage);
            } else {
                toast.error("An error occurred");
                setIsLoading(false);
            }
        }
    };

    const userSubscriptionPlans = async () => {
        try {
            const token = localStorage.getItem("token");
            const user = JSON.parse(localStorage.getItem("user"));
            const headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            };

            const response = await axios.get(
                BASE_URL + GET_SUBSCRIPTION_DETAILS + "?user_id=" + user.id,
                {
                    headers: headers,
                }
            );

            if (response.data.status_code === 200 && response.data.status === true) {
                const user_subscription_details = response.data.data;
                setUserSubscription(user_subscription_details);

            } else if (
                response.data.status_code === 400 &&
                response.data.status === false
            ) {
                toast.error(response?.data?.error[0]);
                setIsLoading(false);
            } else if (
                response.data.status_code === 403 &&
                response.data.status === false
            ) {
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                navigate("/login");
            } else {
                // console.log('response.data::',response)
                toast.error(response.data.message);
            }
        } catch (error) {
            if (error.response && error.response.data) {
                const errorMessage = error.response.data.message || "An error occurred";
                setIsLoading(false);
                // toast.error(errorMessage);
            } else {
                // toast.error("An error occurred");
                setIsLoading(false);
            }
        }
    };

    const onError = (err) => {
        setError(err);
    };
    return (
        <>
            <Loader isLoading={isLoading} />
            <section>
                {userSubscription && userSubscription.subscription_status !== 1 ?
                    <div className="edit-profile-main">
                        <h3 className='main-head-pro'>Plans For You</h3>
                        <div className="upper-form-main">
                            <div className="form-main-name-detal vip-sub d-flex">
                                <div className="vip-suscrib">
                                    <h3>{subscriptionPlans.name}</h3>
                                    <div className="month-price">
                                        <p className='month-date'><span> <sup>£</sup>{subscriptionPlans.price}</span> /month</p>
                                        <div className="feature-main">
                                            <h6>Features you’ll love:</h6>
                                            <ul>
                                                <li>
                                                    <img src={tick} alt="" /> <span dangerouslySetInnerHTML={{ __html: subscriptionPlans.features }}></span>
                                                </li>
                                            </ul>
                                            {userSubscription.subscription_status == 0 ? <div className="upper-btn-login-page  mt-4 w-100 "><button className="edit-butt w-100 justify-content-center" onClick={() => { handleShow(); handlePlanSelect(subscriptionPlans) }}>Upgrade Now</button></div> :
                                                <div className="upper-btn-login-page  mt-4 w-100 "><button className="edit-butt w-100 justify-content-center" onClick={() => { handleShow(); handlePlanSelect(subscriptionPlans) }}>Renew Now</button></div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="cred-instruction w-100">
                                    <div className="upper-head-w-logo d-flex">
                                        <h6>Subscription Instructions</h6>
                                        <img src={redlogo} alt="" />
                                    </div>
                                    <ul className='cred-list'>
                                        <li dangerouslySetInnerHTML={{ __html: subscriptionPlans.instruction }}></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="edit-profile-main">
                        <h3 className='main-head-pro'>{userSubscription.subscription_name}</h3>
                        <div className="upper-form-main">
                            <div className="congrats-main">
                                <img src={greentick} alt="" />

                                <h4>Congratulations!</h4>
                                <p>It appears that your subscription to the VIP Plan is already active. There is no need to take further action, as you are currently enjoying all the benefits associated with the VIP Plan.</p>
                            </div>
                        </div>
                    </div>
                }

            </section>
            <Modal show={show} className="modal-logout" onHide={handleClose} plan={selectedPlan}>
                <Modal.Header className="border-0 pb-0" closeButton>

                </Modal.Header>
                <Modal.Body className="modal-padd">
                    <div className="inner-ogout-btn">
                        <h2>Proceed to payment </h2>
                        <p className="text-damble-text">
                            This transaction is non refundable, Are you sure you want to continue?
                        </p>
                    </div>
                    <Form>
                        <Form.Group>
                            <div className="row">
                            </div>
                            {/* Add more Form.Check elements for other packages */}
                        </Form.Group>
                    </Form>
                    <div className="upper-payment-main">
                        {selectedPlan && (
                            <PayPalScriptProvider options={PayPalConfig}>
                                <PayPalButtons createOrder={createOrder} onApprove={onApprove} onError={onError} />
                            </PayPalScriptProvider>
                        )}
                    </div>
                </Modal.Body>


                {/* <Button variant="primary" onClick={handleSaveChanges}>
          Buy Now
        </Button> */}


            </Modal>
        </>
    )
}

export default Subscription